import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, vModelSelect as _vModelSelect, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vShow as _vShow, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "form__input" }
const _hoisted_3 = ["id", "name"]
const _hoisted_4 = ["value"]
const _hoisted_5 = {
  key: 0,
  class: "buffer buffer--large"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(_ctx.formClass)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "form__label"
        }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("select", {
        id: _ctx.id,
        name: _ctx.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args)))
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.parsedOptions, (option) => {
          return (_openBlock(), _createElementBlock("option", {
            value: option.value
          }, _toDisplayString(option.text), 9 /* TEXT, PROPS */, _hoisted_4))
        }), 256 /* UNKEYED_FRAGMENT */))
      ], 40 /* PROPS, NEED_HYDRATION */, _hoisted_3), [
        [_vModelSelect, _ctx.inputValue]
      ]),
      _cache[2] || (_cache[2] = _createElementVNode("i", { class: "choices__chevron fi-angle-down" }, null, -1 /* HOISTED */)),
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5))
        : _createCommentVNode("v-if", true)
    ]),
    _withDirectives(_createElementVNode("div", { class: "form__error" }, _toDisplayString(_ctx.errorMessage), 513 /* TEXT, NEED_PATCH */), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 2 /* CLASS */))
}