import { createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withModifiers as _withModifiers, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, {
    name: "modal-animation",
    persisted: ""
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", {
        class: "modal",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
      }, [
        (_ctx.clickToClose)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "modal__close",
              style: _normalizeStyle({'max-width': _ctx.maxWidth})
            }, _cache[2] || (_cache[2] = [
              _createElementVNode("i", { class: "fi-cancel text-l pointer" }, null, -1 /* HOISTED */)
            ]), 4 /* STYLE */))
          : _createCommentVNode("v-if", true),
        _createVNode(_Transition, {
          name: "modal-animation-inner",
          persisted: ""
        }, {
          default: _withCtx(() => [
            _withDirectives(_createElementVNode("div", {
              class: "modal__inner",
              style: _normalizeStyle({'max-width': _ctx.maxWidth}),
              onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
            }, [
              _createCommentVNode(" Modal Content "),
              _renderSlot(_ctx.$slots, "default")
            ], 4 /* STYLE */), [
              [_vShow, _ctx.modalOpen]
            ])
          ], undefined, true),
          _: 3 /* FORWARDED */
        })
      ], 512 /* NEED_PATCH */), [
        [_vShow, _ctx.modalOpen]
      ])
    ], undefined, true),
    _: 3 /* FORWARDED */
  }))
}