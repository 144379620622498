<template>
    <transition name="modal-animation">
        <div v-show="modalOpen" class="modal" @click="close">
            <div v-if="clickToClose" class="modal__close" :style="{'max-width': maxWidth}">
                <i class="fi-cancel text-l pointer"></i>
            </div>
            <transition name="modal-animation-inner">
                <div v-show="modalOpen" class="modal__inner" :style="{'max-width': maxWidth}" @click.stop>
                    <!-- Modal Content -->
                    <slot/>
                </div>
            </transition>
        </div>
    </transition>
</template>

<script lang="ts">
import {defineComponent, computed, inject, Ref, watch, ref} from 'vue';

export default defineComponent({
    props: {
        name: {type: String, required: true},
        clickToClose: {type: Boolean, default: true},
        maxWidth: {type: String, default: '640px'},
    },
    emits: ['beforeOpen', 'beforeClose'],
    setup(props, {emit}) {
        const openModals = inject('openModals') as Ref<string[]>;
        const openModalOptions = inject('openModalOptions') as Ref<{}>;

        const modalActive = computed((): boolean => {
            return openModals.value.includes(props.name);
        });

        const modalOpen = ref(false);

        watch(modalActive, (currentValue, oldValue) => {
            if (currentValue) {
                emit('beforeOpen', openModalOptions.value);
            } else {
                emit('beforeClose');
            }

            modalOpen.value = currentValue;
        });


        return {
            modalOpen,
            openModals,
            openModalOptions,
        };
    },
    methods: {
        close(): void {
            if (this.clickToClose) {
                this.$modal.hide(this.name);
            }
        },
    },
});
</script>
