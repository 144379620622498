<template>
    <div :class="{
    'form__field': true,
    'form__field--invalid': !!errorMessage,
    'form__field--small': small,
    }">
        <label :for="id" class="form__label" v-if="label">{{ label }}</label>
        <div class="form__input">
            <input
                :class="css"
                :placeholder="placeholder ? placeholder : undefined"
                :name="name"
                :type="inputType"
                :id="id"
                v-model="inputValue"
                :autocomplete="autocomplete"
                :disabled="disabled"
                @input="onChange"
                @keyup="startedTyping"
                @blur="onBlur"
                v-if="inputType !== 'textarea'"
            >
            <textarea
                :class="css"
                :placeholder="placeholder ? placeholder : undefined"
                :name="name"
                :id="id"
                :type="inputType"
                v-model="inputValue"
                @input="onChange"
                @keyup="startedTyping"
                @blur="onBlur"
                v-if="inputType === 'textarea'"
                :rows="rows"
            ></textarea>
        </div>

        <div class="form__hint" v-if="hint">
            {{ hint }}
        </div>

        <div class="form__error" v-show="errorMessage">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

export default defineComponent({
    props: {
        'name': {type: String, required: true},
        'css': {type: String, default: ''},
        'inputType': {type: String, default: 'text'},
        'modelValue': {type: [String, Number], default: ''},
        'placeholder': {type: String, default: ''},
        'label': {type: String, default: null},
        'hint': {type: String, default: null},
        'autocomplete': {type: String, default: null},
        'small': {type: [Boolean], default: false},
        'disabled': {type: [Boolean], default: false},
        'rows': {type: Number, default: 5},
        'validationState': {type: Object},
        'laravelErrors': {type: Array as PropType<ValidationErrors>, default: () => []},
    },
    emits: [
        'update:modelValue',
        'change',
        'blur',
    ],
    data() {
        return {
            id: '',
            inputValue: '',
            changed: false,
        };
    },
    computed: {
        errorMessage(): string {
            if (!this.changed && this.laravelErrors.length) {
                return this.laravelErrors[0];
            }

            if (this.validationState && this.validationState.$errors && this.validationState.$errors.length > 0) {
                return this.validationState.$errors[0].$message;
            }

            return '';
        },
    },
    methods: {
        onChange(e: Event) {
            // TODO: debounce
            this._syncToModel();
        },
        onBlur(e: Event) {
            this._syncToModel();
            this.$emit('blur');
        },
        _syncToModel() {
            if (this.modelValue !== this.inputValue) {
                this.$emit('update:modelValue', this.inputValue);
                this.$emit('change', this.inputValue);
            }
        },
        startedTyping() {
            this.changed = true;
        },
    },
    watch: {
        modelValue(newValue) {
            if (newValue !== this.inputValue) {
                this.inputValue = newValue;
            }
        },
    },
    mounted() {
        this.id = this.name + '_' + (Math.random() + 1).toString(36).substring(7);
        this.inputValue = this.modelValue.toString();
    },
});
</script>
