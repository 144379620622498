import {App, ref} from 'vue';
import modalComponent from '../components/global/modal.vue';

export default {
    install: (app: App) => {
        app.component('modal', modalComponent);

        const openedModals = ref([] as string[]);
        const openedModalOptions = ref({});

        app.provide('openModals', openedModals);
        app.provide('openModalOptions', openedModalOptions);

        app.config.globalProperties.$modal = {
            show(modalName: string, options?: object) {
                if (options) {
                    openedModalOptions.value = options;
                }

                if (openedModals.value.includes(modalName)) {
                    return;
                }
                openedModals.value.push(modalName);
                if (!document.body.classList.contains('modal__lock')) {
                    document.body.classList.add('modal__lock');
                }
            },
            hide(modalName: string) {
                const index = openedModals.value.indexOf(modalName);
                if (index > -1) {
                    openedModals.value.splice(index, 1);
                }
                openedModalOptions.value = {};
                if (document.body.classList.contains('modal__lock')) {
                    document.body.classList.remove('modal__lock');
                }
            },
            hideAll() {
                openedModals.value = [];
            },
        };
    },
};
