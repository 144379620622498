import {App, Component} from 'vue';
import dropdown from '../components/global/dropdown.vue';
import InputText from '../components/global/input.vue';
import modal from '../components/global/modal.vue';
import Address from '../components/Address.vue';
import modalPlugin from '../plugins/modal';

export default function(vue: App) {
    vue.config.compilerOptions.whitespace = 'preserve';
    vue.component('drop-down', dropdown as Component);
    vue.component('input-text', InputText as Component);
    // vue.component('address-input', Address as Component);

    vue.use(modalPlugin);
}
