import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, normalizeClass as _normalizeClass, withDirectives as _withDirectives, vModelText as _vModelText, createElementVNode as _createElementVNode, vShow as _vShow } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "form__input" }
const _hoisted_3 = ["placeholder", "name", "type", "id", "autocomplete", "disabled"]
const _hoisted_4 = ["placeholder", "name", "id", "type", "rows"]
const _hoisted_5 = {
  key: 1,
  class: "form__hint"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
    'form__field': true,
    'form__field--invalid': !!_ctx.errorMessage,
    'form__field--small': _ctx.small,
    })
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id,
          class: "form__label"
        }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_1))
      : _createCommentVNode("v-if", true),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.inputType !== 'textarea')
        ? _withDirectives((_openBlock(), _createElementBlock("input", {
            key: 0,
            class: _normalizeClass(_ctx.css),
            placeholder: _ctx.placeholder ? _ctx.placeholder : undefined,
            name: _ctx.name,
            type: _ctx.inputType,
            id: _ctx.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
            autocomplete: _ctx.autocomplete,
            disabled: _ctx.disabled,
            onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            onKeyup: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.startedTyping && _ctx.startedTyping(...args))),
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args)))
          }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_3)), [
            [_vModelDynamic, _ctx.inputValue]
          ])
        : _createCommentVNode("v-if", true),
      (_ctx.inputType === 'textarea')
        ? _withDirectives((_openBlock(), _createElementBlock("textarea", {
            key: 1,
            class: _normalizeClass(_ctx.css),
            placeholder: _ctx.placeholder ? _ctx.placeholder : undefined,
            name: _ctx.name,
            id: _ctx.id,
            type: _ctx.inputType,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.inputValue) = $event)),
            onInput: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.onChange && _ctx.onChange(...args))),
            onKeyup: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.startedTyping && _ctx.startedTyping(...args))),
            onBlur: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.onBlur && _ctx.onBlur(...args))),
            rows: _ctx.rows
          }, null, 42 /* CLASS, PROPS, NEED_HYDRATION */, _hoisted_4)), [
            [_vModelText, _ctx.inputValue]
          ])
        : _createCommentVNode("v-if", true)
    ]),
    (_ctx.hint)
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.hint), 1 /* TEXT */))
      : _createCommentVNode("v-if", true),
    _withDirectives(_createElementVNode("div", { class: "form__error" }, _toDisplayString(_ctx.errorMessage), 513 /* TEXT, NEED_PATCH */), [
      [_vShow, _ctx.errorMessage]
    ])
  ], 2 /* CLASS */))
}