<template>
    <div :class="formClass">
        <label v-if="label" :for="id" class="form__label">{{ label }}</label>
        <div class="form__input">
            <select :id="id" :name="name" v-model="inputValue" @change="onChange">
                <option v-for="option in parsedOptions" :value="option.value">{{ option.text }}</option>
            </select>

            <i class="choices__chevron fi-angle-down"></i>

            <div v-if="loading" class="buffer buffer--large">
            </div>
        </div>

        <div class="form__error" v-show="errorMessage">
            {{ errorMessage }}
        </div>
    </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

export default defineComponent({
    props: {
        'modelValue': {type: [String, Number], default: ''}, // current model value
        'name': {type: String, required: true}, // name of the input
        'label': {type: String, default: ''}, // label of the select
        'placeholder': {type: String, default: ''}, // first item text for unselected
        'options': {type: Array as PropType<DropdownOptions>, required: true}, // list of options

        // styling
        'css': {type: String}, // extra classes as string
        'small': {type: Boolean, default: false},
        'loading': {type: Boolean, default: false}, // display loading effect
        'validationState': {type: Object},
        'laravelErrors': {type: Array as PropType<ValidationErrors>, default: () => []},
    },
    emits: [
        'update:modelValue',
        'change',
    ],
    data() {
        return {
            id: '',
            inputValue: '',
            valueChanged: false,
        };
    },
    computed: {
        formClass(): VueClassMapping {
            const classes: VueClassMapping = {
                'form__field': true,
                'form__field--dropdown': true,
                'form__field--small': !!this.small,
                'form__field--invalid': !!this.errorMessage,
                'loading': this.loading,
            };

            if (this.css) {
                this.css.split(' ').forEach((item: string) => {
                    classes[item] = true;
                });
            }

            return classes;
        },
        parsedOptions(): DropdownOptions {
            const options = this.options;
            const parsed = [];

            // if it is an array then we consider it to be in correct format
            options.forEach(function(item) {
                parsed.push({
                    text: item.text.toString(),
                    value: item.value.toString(),
                });
            });

            if (this.placeholder) {
                parsed.unshift({
                    text: this.placeholder.toString(),
                    value: '',
                });
            }

            if (parsed.length === 0) {
                parsed.unshift({
                    text: '',
                    value: '',
                });
            }

            return parsed;
        },
        errorMessage(): string {
            if (!this.valueChanged && this.laravelErrors.length) {
                return this.laravelErrors[0];
            }

            if (this.validationState && this.validationState.$errors && this.validationState.$errors.length > 0) {
                return this.validationState.$errors[0].$message;
            }

            return '';
        },
    },
    methods: {
        onChange(e: Event) {
            if (this.modelValue.toString() === this.inputValue) {
                // if value did not change do not emit events, or sync value
                return;
            }

            this.$emit('update:modelValue', this.inputValue);
            this.$emit('change', this.inputValue);
            this.valueChanged = true;
        },
        isValidValue(value: string|number): boolean {
            if (!value) {
                return false;
            }

            const option = this.parsedOptions.find((option) => option.value === value.toString());

            return option !== undefined;
        },
        detectDefaultValue() {
            if (this.isValidValue(this.modelValue)) {
                this.inputValue = this.modelValue.toString();
            } else {
                this.inputValue = this.parsedOptions[0].value;
            }
        },
    },
    watch: {
        modelValue() {
            this.detectDefaultValue();
        },
    },
    mounted() {
        this.id = this.name + '_' + (Math.random() + 1).toString(36).substring(7);
        this.detectDefaultValue();
    },
});
</script>
